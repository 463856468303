import { useState, useMemo, useEffect } from 'react'
import {
  Box,
  BoxProps,
  forwardRef,
  Button,
  IconButton,
  Input,
  useNumberInput,
} from '@chakra-ui/react'
import { debounce } from 'lodash'

import { OmPlus, OmMinus } from '../icons'

interface IQuantityButtonFormProps {
  size?: 'sm' | 'md'
  value: number
  maxValue: number
  onChange?: (valueAsString: string, valueAsNumber: number) => void
}

const QuantityButtonForm: React.FC<IQuantityButtonFormProps> = ({
  value,
  maxValue,
  onChange,
  size,
}) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    onChange,
    focusInputOnChange: false,
    step: 1,
    defaultValue: value,
    min: 0,
    max: maxValue,
  })
  const input = getInputProps()
  const decrement = getDecrementButtonProps()
  const increment = getIncrementButtonProps()

  return (
    <Box display="flex">
      <IconButton
        {...decrement}
        size={size}
        as="div"
        aria-label="Decrement"
        icon={<OmMinus />}
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
      />
      <Input
        {...input}
        size={size}
        borderLeft={0}
        borderRight={0}
        borderRadius={0}
        textAlign="center"
        _focus={{ boxShadow: 'none', borderColor: 'coral.500' }}
      />
      <IconButton
        {...increment}
        size={size}
        as="div"
        aria-label="Increment"
        icon={<OmPlus />}
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
      />
    </Box>
  )
}

export interface IQuantityButtonProps extends BoxProps {
  isDisabled?: boolean
  size?: 'sm' | 'md'
  value: number
  emptyLabel: string
  maxValue: number
  onValueChange?: (value: number) => void
}

export const QuantityButton = forwardRef<IQuantityButtonProps, 'div'>((props, ref) => {
  const {
    value: initialValue,
    emptyLabel,
    maxValue,
    onValueChange,
    size = 'sm',
    isDisabled,
    ...rest
  } = props
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onChange = useMemo(() => {
    const onChange = (value: number) => {
      onValueChange && onValueChange(value)
    }
    const onChangeDebounced = debounce(onChange, 300, {
      leading: false,
      trailing: true,
    })
    return onChangeDebounced
  }, [onValueChange])

  const handleChange = (_valueAsString: string, valueAsNumber: number) => {
    const val = valueAsNumber > maxValue ? maxValue : valueAsNumber
    setValue(val)
    onChange(val)
  }
  const handleEmptyClick = () => {
    setValue(1)
    onChange(1)
  }

  return (
    <Box ref={ref} {...rest}>
      {value > 0 ? (
        <QuantityButtonForm size={size} onChange={handleChange} value={value} maxValue={maxValue} />
      ) : (
        <Button size={size} width="100%" onClick={handleEmptyClick} isDisabled={isDisabled}>
          {emptyLabel}
        </Button>
      )}
    </Box>
  )
})
