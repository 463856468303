import queryString from 'query-string'
import { uniq, find } from 'lodash'

import { history, FACETS, IAvailableFacet } from '../../../lib'
import { IFetchMultipleQueryOptionsWithSearch, IFacet as IApiFacet } from '../../../api'
import { IFilter, IFetchProductsRequestQuery } from './actions'

const ALLOWED_FILTER_KEYS = [...FACETS.map((f) => f.field)]

export function getProductSearchQueryString(params: queryString.ParsedQuery) {
  const query: Pick<IFetchProductsRequestQuery, 'sort' | 'filters'> = {}
  if (params.sort) {
    query.sort = (Array.isArray(params.sort) ? params.sort : [params.sort]).filter(
      (s): s is string => s !== null,
    )
  }
  if (params.filters) {
    const filterKeys = Array.isArray(params.filters) ? params.filters : [params.filters]
    const filters: IFilter[] = []
    FACETS.filter((f) => filterKeys.includes(f.field)).forEach((f) => {
      filters.push({
        key: f.field,
        value: f.value,
      })
    })
    query.filters = filters
  }
  return query
}

export function setProductSearchQueryString(query: IFetchProductsRequestQuery) {
  const qsParams: { [key: string]: string[] } = {}
  if (query.search) {
    qsParams.q = [query.search]
  }
  if (query.sort) {
    qsParams.sort = query.sort
  }
  if (query.filters) {
    qsParams.filters = uniq(
      query.filters.map((f) => f.key).filter((k) => ALLOWED_FILTER_KEYS.includes(k)),
    )
  }

  const qs = queryString.stringify(qsParams)
  const pathname = history.location!.pathname
  const path = qs ? `${pathname}?${qs}` : pathname
  history.push(path)
}

export function storeQueryToAPIQuery(
  query: IFetchProductsRequestQuery,
): IFetchMultipleQueryOptionsWithSearch {
  const filters: { [key: string]: string } = {}
  query.filters?.forEach((q) => {
    if (!(q.key in filters)) {
      // avoid duplicates
      filters[q.key] = q.value
    }
  })

  return {
    ...query,
    filters,
  }
}

export function getFacetsFromQuery(
  query: IFetchProductsRequestQuery | undefined,
  facets: IApiFacet[],
) {
  const available: IAvailableFacet[] = []
  const filterKeys = query?.filters?.map((f) => f.key) || []
  FACETS.forEach((facet) => {
    const result = find(facets, ['field', facet.field])
    const bucket = result && find(result.buckets, ['key_as_string', facet.value])
    if (bucket) {
      available.push({
        ...facet,
        count: bucket.doc_count,
        isSelected: filterKeys.includes(facet.field),
      })
    }
  })

  const selectedFacetsUnordered = available.filter((f) => f.isSelected)
  const selectedFacetKeys = selectedFacetsUnordered.map((f) => f.field)
  const selected: IAvailableFacet[] = []
  query?.filters?.forEach((f) => {
    if (selectedFacetKeys.includes(f.key)) {
      selected.push(find(selectedFacetsUnordered, ['field', f.key])!)
    }
  })

  return {
    available,
    selected,
  }
}
