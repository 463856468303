import { APIService } from './service'
import './models/account'
import './models/address'
import './models/authtoken'
import './models/basket-item'
import './models/basket'
import './models/category'
import './models/cutoff-time'
import './models/duplicate-order-check'
import './models/favourite'
import './models/menu-item'
import './models/menu'
import './models/non-delivery-days'
import './models/order-item'
import './models/order-settings'
import './models/order'
import './models/password-reset-request'
import './models/password-reset'
import './models/product'
import './models/public-product'
import './models/ticket-comment'
import './models/ticket'
import './models/user'

export { JsonApiError } from './service'
export * from './models/account'
export * from './models/address'
export * from './models/authtoken'
export * from './models/basket-item'
export * from './models/basket'
export * from './models/category'
export * from './models/cutoff-time'
export * from './models/duplicate-order-check'
export * from './models/favourite'
export * from './models/menu-item'
export * from './models/menu'
export * from './models/non-delivery-days'
export * from './models/order-item'
export * from './models/order-settings'
export * from './models/order'
export * from './models/password-reset-request'
export * from './models/password-reset'
export * from './models/product'
export * from './models/public-product'
export * from './models/ticket-comment'
export * from './models/ticket'
export * from './models/user'
export * from './types'

const API = new APIService(process.env.REACT_APP_API_URL!!)

export default API
