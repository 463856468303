import { Box, BoxProps, forwardRef, Button, Image, Grid, AspectRatio } from '@chakra-ui/react'

import { QuantityButton } from '../quantity-button'
import { IProduct, TMenuAny, TMenuItemAny } from '../../api'
import { useMenuItemDetail } from '../../lib'

export interface IOrderItemBoxProps extends BoxProps {
  product: IProduct
  menu?: TMenuAny
  item?: TMenuItemAny
}

export const MenuItemBox = forwardRef<IOrderItemBoxProps, 'button'>((props, ref) => {
  const { product, menu, item, ...rest } = props
  const {
    canEdit,
    canRemove,
    splitsSubtotal,
    packsSubtotal,
    onUpdateSplitQuantity,
    onUpdatePackQuantity,
    onRemove,
  } = useMenuItemDetail(product.id, menu, item)

  const handleRemove = () => onRemove()
  const splitQuantity = item?.splitQuantity ?? 0
  const packQuantity = item?.packQuantity ?? 0

  const images = product.images.length ? product.images.filter((image) => image.medium) : []
  const image = images.length ? product.images[0].medium : '/images/product-placeholder.png'

  const imageSize = ['4rem', '10rem']

  return (
    <Box ref={ref} {...rest}>
      <Box display="flex">
        <Box width={imageSize} height={imageSize} minWidth={imageSize}>
          <AspectRatio maxW={imageSize} ratio={1}>
            <Box>
              <Image
                src={image}
                width="100%"
                height="100%"
                objectFit="scale-down"
                alt={product.name}
                borderRadius="sm"
              />
            </Box>
          </AspectRatio>
        </Box>
        <Box
          ml={[2, 4]}
          flexGrow={1}
          minHeight={imageSize}
          display="flex"
          justifyContent="space-between"
          flexDirection="column">
          <Box fontWeight="bold" fontSize="sm">
            <Box as="span" display="block" color="gray.500">
              {product.id}
            </Box>
            <Box as="span" display="block">
              {product.name}
            </Box>
          </Box>
          <Box>
            <Box display="flex" flexDirection="row-reverse">
              <Grid
                display="inline-grid"
                mt={4}
                gap={4}
                textAlign="right"
                alignItems="center"
                gridTemplateColumns={['auto 7rem', 'auto 7rem 5rem']}>
                {product.canSplit && (
                  <>
                    <Box color="gray.600">{product.splitSize}</Box>
                    <Box>
                      <QuantityButton
                        isDisabled={!canEdit}
                        value={splitQuantity}
                        emptyLabel={`Add ${product.splitSize}`}
                        onValueChange={onUpdateSplitQuantity}
                        maxValue={product.packQuantity - 1}
                      />
                    </Box>
                    <Box color="gray.600" display={['none', 'block']}>
                      £{splitsSubtotal}
                    </Box>
                  </>
                )}

                <Box color="gray.600">{product.packSize}</Box>
                <Box>
                  <QuantityButton
                    isDisabled={!canEdit}
                    value={packQuantity}
                    emptyLabel={`Add ${product.packSize}`}
                    onValueChange={onUpdatePackQuantity}
                    maxValue={999}
                  />
                </Box>
                <Box color="gray.600" display={['none', 'block']}>
                  £{packsSubtotal}
                </Box>
              </Grid>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={4}>
              <Box>
                {canRemove && (
                  <Button variant="link" colorScheme="black" onClick={handleRemove}>
                    Remove
                  </Button>
                )}
              </Box>
              <Box fontSize="lg" fontWeight="bold">
                £{item?.subtotal ?? '0.00'}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})
