import { Box, BoxProps, Button, forwardRef } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { IProduct } from '../../api'
import { useSelectedBasketItem } from '../../lib'
import { QuantityButton } from '../quantity-button'

export interface IBasketButtonProps extends BoxProps {
  product: IProduct
  productUrl: string
}

export const BasketButtons = forwardRef<IBasketButtonProps, 'div'>((props, ref) => {
  const { product, productUrl, ...rest } = props
  const {
    isAvailable,
    substitutes,
    canSplit,
    splitPrice,
    packQuantity,
    casePrice,
    packSize,
    splitSize,
  } = product
  const { basket, item, onSelectBasket, onUpdatePackQuantity, onUpdateSplitQuantity } =
    useSelectedBasketItem(product.id)

  if (!isAvailable) {
    return (
      <Box ref={ref} {...rest}>
        {canSplit && (
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box as="span" color="gray.500">
                {splitSize}
              </Box>
              <Box as="span">£{splitPrice}</Box>
            </Box>
          </Box>
        )}
        <Box mt={2}>
          <Box display="flex" justifyContent="space-between">
            <Box as="span" color="gray.500">
              {packSize}
            </Box>
            <Box as="span">£{casePrice}</Box>
          </Box>
          {!!substitutes.length && (
            <Button size="sm" width="100%" mt={1} as={RouterLink} to={productUrl} variant="outline">
              View Substitutes
            </Button>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Box ref={ref} {...rest}>
      {canSplit && (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box as="span" color="gray.500">
              {splitSize}
            </Box>
            <Box as="span">£{splitPrice}</Box>
          </Box>
          {basket ? (
            <QuantityButton
              size="sm"
              mt={1}
              value={item ? item.splitQuantity : 0}
              emptyLabel={`Add ${splitSize}`}
              onValueChange={onUpdateSplitQuantity}
              maxValue={packQuantity - 1}
            />
          ) : (
            <Button size="sm" width="100%" mt={1} onClick={() => onSelectBasket(1, 0)}>
              Add {splitSize}
            </Button>
          )}
        </Box>
      )}
      <Box mt={2}>
        <Box display="flex" justifyContent="space-between">
          <Box as="span" color="gray.500">
            {packSize}
          </Box>
          <Box as="span">£{casePrice}</Box>
        </Box>
        {basket ? (
          <QuantityButton
            size="sm"
            mt={1}
            value={item ? item.packQuantity : 0}
            emptyLabel={`Add ${packSize}`}
            onValueChange={onUpdatePackQuantity}
            maxValue={999}
          />
        ) : (
          <Button size="sm" width="100%" mt={1} onClick={() => onSelectBasket(0, 1)}>
            Add {packSize}
          </Button>
        )}
      </Box>
    </Box>
  )
})
