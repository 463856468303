import React from 'react'
import { Box, BoxProps, Image, IconButton, Icon, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { OmMenu, OmHeart, OmHeartSolid, OmFrozen, OmPromotion, OmClock } from '../icons'
import { IconBadge } from '../icon-badge'
import { TProduct } from '../../api'
import { productPath, useMenuPicker, useFavourite } from '../../lib'
import { BasketButtons } from './basket-buttons'

export interface IProductBoxProps extends BoxProps {
  product: TProduct
  categoryId?: string
}

export const ProductBox: React.FC<IProductBoxProps> = ({
  product,
  categoryId,
  children,
  ...boxProps
}) => {
  const images = product.images.length ? product.images.filter((image) => image.medium) : []
  const image = images.length ? product.images[0].medium : '/images/product-placeholder.png'
  const productUrl = productPath(product, categoryId)
  const { onOpen: onOpenMenuPicker } = useMenuPicker()
  const {
    isFavourite,
    onToggle: onToggleFavourite,
    isFetching: isFavouriteFetching,
  } = useFavourite(product)

  const handleOpenMenuPicker = () => {
    onOpenMenuPicker({
      productId: product.id,
    })
  }

  const hasIcons = product.isFrozen || product.isOnPromotion || product.isPreOrder

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      borderColor="gray.300"
      _hover={{
        borderColor: 'gray.500',
      }}
      borderWidth={1}
      borderRadius="sm"
      {...boxProps}
    >
      <Link as={RouterLink} to={productUrl}>
        <Box position="relative" pt="100%" width="100%">
          <Box
            position="absolute"
            top={0}
            left={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <Image
              src={image}
              width="100%"
              height="100%"
              objectFit="scale-down"
              alt={product.name}
            />
            {hasIcons && (
              <Box top={1} left={1} position="absolute">
                {product.isFrozen && (
                  <IconBadge icon={OmFrozen} size="sm" color="#82D0F6" marginRight={2} />
                )}
                {product.isOnPromotion && (
                  <IconBadge icon={OmPromotion} size="sm" color="coral.500" marginRight={2} />
                )}
                {product.isPreOrder && (
                  <IconBadge icon={OmClock} size="sm" color="birchallBlue.500" marginRight={2} />
                )}
              </Box>
            )}
            {!product.isAvailable && (
              <Box
                top={0}
                left={0}
                width="100%"
                height="100%"
                backgroundColor="blackAlpha.500"
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textTransform="uppercase"
                textAlign="center"
                color="white"
                fontWeight="bold"
                fontSize={['lg', '2xl']}
              >
                <Box as="span">Temporarily Out of Stock</Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          flexDirection="column"
          justifyContent="space-between"
          p={2}
          fontWeight="bold"
          fontSize="sm"
        >
          <Box>
            <Box as="span" display="block" color="gray.500">
              {product.id}
            </Box>
            <Box as="span" display="block">
              {product.name}
            </Box>
          </Box>
        </Box>
      </Link>
      <Box px={2} as="span" display="block" fontWeight="normal" fontSize="xs" color="gray.700">
        {product.cutoffTime && (
          <>Order by {product.cutoffTime} on the working day prior to your delivery.</>
        )}
        {product.isPreOrder && <>This is a pre-ordered product. Lead times may vary.</>}
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        p={2}
        pt={0}
        fontWeight="bold"
        fontSize="sm"
      >
        <Box mt={2}>
          {product.type === 'products' && (
            <BasketButtons product={product} productUrl={productUrl} />
          )}
          <Box borderTopWidth={1} borderColor="gray.300" mt={2} pt={2}>
            <Box display="flex" justifyContent="center">
              <IconButton
                aria-label="Add to list"
                icon={<OmMenu w={6} h={6} />}
                variant="ghost"
                colorScheme="black"
                mx={2}
                onClick={handleOpenMenuPicker}
              />
              <IconButton
                aria-label="Add to favourites"
                icon={<Icon as={isFavourite ? OmHeartSolid : OmHeart} w={6} h={6} />}
                variant="ghost"
                colorScheme="black"
                mx={2}
                onClick={onToggleFavourite}
                isLoading={isFavouriteFetching}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
