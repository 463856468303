import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { menuDetailSelector } from '../../stores/menu'
import { orderDetailSelector } from '../../stores/order'

import {
  SHOW_ORDER_PICKER,
  HIDE_ORDER_PICKER,
  IShowOrderPickerProps,
  selectOrderPickerCreateState,
  selectOrderPickerIsOpen,
  selectOrderPickerProps,
  ORDER_PICKER_BASKET_SELECTED,
  ORDER_PICKER_ORDER_SELECTED,
  IBasketFormData,
  SHOW_ORDER_PICKER_PICK_EXISTING,
} from '../../stores/ui'

export function useOrderPicker() {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectOrderPickerIsOpen)
  const pickerProps = useSelector(selectOrderPickerProps)
  const pickerCreateState = useSelector(selectOrderPickerCreateState)

  const { isFetching, error } = useMemo(() => {
    return {
      isFetching: !!pickerCreateState?.isFetching,
      error: pickerCreateState?.error,
    }
  }, [pickerCreateState])

  const existingOrderFilter = useMemo(() => {
    const { formData } = pickerProps ?? {}
    if (formData) {
      return {
        address: formData.address,
        deliveryDate: formData.deliveryDate,
      }
    }
  }, [pickerProps])

  const { item: order } = useSelector(orderDetailSelector)(
    pickerProps?.order ? pickerProps.order.id : '',
  )
  const { item: menu } = useSelector(menuDetailSelector)(
    pickerProps?.menu ? pickerProps.menu.id : '',
  )
  const productCodesToCopy = useMemo(() => {
    if (!order && !menu) {
      return []
    }
    return order
      ? order.items.map((item) => item.productCode)
      : menu
        ? menu.items.map((item) => item.productCode)
        : []
  }, [menu, order])

  const onOpen = useCallback(
    (props: IShowOrderPickerProps) => {
      dispatch(SHOW_ORDER_PICKER(props))
    },
    [dispatch],
  )

  const onClose = useCallback(() => {
    dispatch(HIDE_ORDER_PICKER())
  }, [dispatch])

  const onSelectBasket = useCallback(
    (basketId: string) => {
      dispatch(ORDER_PICKER_BASKET_SELECTED(basketId))
      console.log("ORDER_PICKER_BASKET_SELECTED = ", basketId)
    },
    [dispatch],
  )

  const onSelectOrder = useCallback(
    (orderId: string) => {
      dispatch(ORDER_PICKER_ORDER_SELECTED(orderId))
    },
    [dispatch],
  )

  const onSelectExisting = useCallback(
    (props: IBasketFormData) => {
      dispatch(SHOW_ORDER_PICKER_PICK_EXISTING(props))
    },
    [dispatch],
  )

  return {
    isOpen,
    isFetching,
    error,
    onOpen,
    onClose,
    onSelectBasket,
    onSelectOrder,
    onSelectExisting,
    existingOrderFilter,
    productCodesToCopy,
  }
}

export function useOrderPickerOpener() {
  const dispatch = useDispatch()
  const onOpen = useCallback(
    (props: IShowOrderPickerProps) => {
      dispatch(SHOW_ORDER_PICKER(props))
    },
    [dispatch],
  )
  return {
    onOpen,
  }
}
