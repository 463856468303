import {
  Box,
  BoxProps,
  Button,
  forwardRef,
  Link,
  Stack,
  StackDivider,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  TBasketItemAny,
  TBasketWithAddressAndItems,
  TOrderItemAny,
  TOrderWithAddressAndItems,
} from '../../api'
import {
  basketPath,
  orderPath,
  useItemSubstitutes,
  useOrderOrBasketDetail,
  useSelectedBasket,
} from '../../lib'
import { BasketFormModalButton } from '../basket-form'
import { ConfirmDialog, IUseConfirmDialogProps, useConfirmDialog } from '../confirm-dialog'
import { Definition, DefinitionList, DefinitionTerm } from '../order-box'
import { ItemBox } from './item-box'

export interface IOrderPageContentsProps extends BoxProps {
  order: TBasketWithAddressAndItems | TOrderWithAddressAndItems
}

export const OrderPageContents = forwardRef<IOrderPageContentsProps, 'button'>((props, ref) => {
  const { order, ...rest } = props
  const { basket: selectedBasket } = useSelectedBasket()
  const {
    isBasket,
    basketErrorMessage,
    orderMessage,
    showReciprocalLink,
    canEdit,
    canSubmit,
    canCancel,
    addressDisplay,
    deliveryDateDisplay,
    cutoffDisplay,
    name,
    showCutoff,
    onCancel,
    onConfirm,
    onSelect,
    handleReorderSuccess,
    handleSelectExisting,
    isDeleting,
    isOpeningForEdit,
  } = useOrderOrBasketDetail(order)

  const isSelected = order === selectedBasket

  const { substitutes } = useItemSubstitutes(order)

  const cancelDialogProps: IUseConfirmDialogProps = isBasket
    ? {
        type: 'remove-basket',
        basketName: name,
        onConfirm: onCancel,
      }
    : {
        type: 'cancel-order',
        orderName: name,
        onConfirm: onCancel,
      }

  const cancelDialog = useConfirmDialog(cancelDialogProps)

  const confirmDialog = useConfirmDialog({
    type: 'confirm-basket',
    basketName: name,
    onConfirm,
  })

  const handleCancel = useCallback(() => {
    cancelDialog.onOpen()
  }, [cancelDialog])

  const handleConfirm = useCallback(() => {
    confirmDialog.onOpen()
  }, [confirmDialog])

  const confirmButton = useMemo(() => {
    if (!isBasket) return null
    const btn = (
      <Button onClick={handleConfirm} isDisabled={!canSubmit}>
        Confirm Order
      </Button>
    )

    if (canSubmit) return btn

    return <Tooltip label="Resolve issues to continue">{btn}</Tooltip>
  }, [isBasket, handleConfirm, canSubmit])

  const messageBox = useMemo(() => {
    if (!showCutoff && !basketErrorMessage && !orderMessage) return null
    return (
      <Stack borderRadius="sm" bg="gray.100" p={4} my={4} spacing={4}>
        {showCutoff && (
          <Text>
            {isBasket ? 'This order must be confirmed before' : 'This order can be edited until'}{' '}
            <Text as="span" fontWeight="bold">
              {cutoffDisplay}
            </Text>
          </Text>
        )}
        {basketErrorMessage && (
          <Text fontWeight="bold" color="coral.500">
            {basketErrorMessage}
          </Text>
        )}
        {orderMessage && (
          <Text>
            <Text as="span" fontWeight="bold">
              {orderMessage}
            </Text>{' '}
            {isBasket && showReciprocalLink && (
              <Link as={RouterLink} to={orderPath(order.order)} color="blue.500">
                View the confirmed order.
              </Link>
            )}
            {!isBasket && showReciprocalLink && (
              <Link as={RouterLink} to={basketPath(order.basket)} color="blue.500">
                View the unconfirmed order.
              </Link>
            )}
          </Text>
        )}
      </Stack>
    )
  }, [
    showCutoff,
    basketErrorMessage,
    orderMessage,
    isBasket,
    cutoffDisplay,
    showReciprocalLink,
    order,
  ])

  return (
    <Box ref={ref} {...rest}>
      {cancelDialog.isOpen && <ConfirmDialog {...cancelDialog} />}
      {confirmDialog.isOpen && <ConfirmDialog {...confirmDialog} />}
      <Box display={[null, null, 'flex']} flexDirection="row-reverse" mt={4}>
        <Box my={4} width={['auto', null, 72]} minWidth={['auto', null, 72]} ml={[0, null, 4]}>
          <Box borderWidth={1} borderRadius="sm" p={4}>
            <DefinitionList>
              <DefinitionTerm m={0}>Title</DefinitionTerm>
              <Definition>{order.title || '—'}</Definition>

              <DefinitionTerm>Purchase Order Number</DefinitionTerm>
              <Definition>{order.poNumber || '—'}</Definition>

              <DefinitionTerm>Delivery Address</DefinitionTerm>
              <Definition>{addressDisplay || '—'}</Definition>

              <DefinitionTerm>Delivery Date</DefinitionTerm>
              <Definition>{deliveryDateDisplay || '—'}</Definition>
            </DefinitionList>
            {isBasket && canEdit && (
              <BasketFormModalButton
                basket={order as TBasketWithAddressAndItems}
                variant="outline"
                mt={4}
              >
                Change
              </BasketFormModalButton>
            )}
          </Box>
        </Box>

        <Box flexGrow={1}>
          {messageBox}

          <Stack direction="column" divider={<StackDivider borderColor="gray.200" />} spacing={4}>
            {(order.items as (TBasketItemAny | TOrderItemAny)[]).map((item, index) => (
              <ItemBox
                key={index}
                order={order}
                item={item}
                substitutes={substitutes[item.productCode] ?? []}
              />
            ))}
          </Stack>

          {order.items.length > 2 && messageBox}

          <Box bg="gray.100" borderRadius="sm" p={4} my={4}>
            <Box as="dl" display="grid" gridTemplateColumns="auto auto" gridGap={4} fontSize="xl">
              <DefinitionTerm>Order Lines</DefinitionTerm>
              <Definition textAlign="right">{order.items.length}</Definition>

              <DefinitionTerm mt={0}>Order Total</DefinitionTerm>
              <Definition textAlign="right">£{order.netTotal}</Definition>
            </Box>
            <Stack mt={4} spacing={4} alignItems="stretch" display={['flex', 'none']}>
              {confirmButton}
              {canEdit && (
                <Button
                  onClick={onSelect}
                  variant={isSelected ? 'outline' : 'solid'}
                  isLoading={isOpeningForEdit}
                >
                  Make Changes
                </Button>
              )}
              {!isBasket && (
                <BasketFormModalButton
                  reorder={order as TOrderWithAddressAndItems}
                  onCreated={handleReorderSuccess}
                  onSelectExisting={handleSelectExisting}
                >
                  Reorder
                </BasketFormModalButton>
              )}
              {canCancel && (
                <Button variant="outline" onClick={handleCancel} isLoading={isDeleting}>
                  {isBasket ? 'Remove Order' : 'Cancel Order'}
                </Button>
              )}
            </Stack>
            <Box
              mt={4}
              display={['none', 'flex']}
              justifyContent="space-between"
              flexDirection="row-reverse"
            >
              <Stack direction="row-reverse" spacing={4}>
                {confirmButton}
                {/* {isBasket && !basketErrorMessage && canSubmit && (
                  <Button onClick={handleConfirm}>
                    Confirm Order
                  </Button>
                )}
                {isBasket && basketErrorMessage && !canSubmit && (
                  <Tooltip label='Hover me'>
                    <Button onClick={handleConfirm} isDisabled={true} >
                      Confirm Order
                    </Button>
                  </Tooltip>
                )} */}
                {canEdit && (
                  <Button
                    onClick={onSelect}
                    variant={isSelected ? 'outline' : 'solid'}
                    isLoading={isOpeningForEdit}
                  >
                    Make Changes
                  </Button>
                )}
                {!isBasket && (
                  <BasketFormModalButton
                    reorder={order as TOrderWithAddressAndItems}
                    onCreated={handleReorderSuccess}
                    onSelectExisting={handleSelectExisting}
                  >
                    Reorder
                  </BasketFormModalButton>
                )}
              </Stack>
              <Box>
                {canCancel && (
                  <Button variant="outline" onClick={handleCancel} isLoading={isDeleting}>
                    {isBasket ? 'Remove Order' : 'Cancel Order'}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})
