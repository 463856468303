import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  CREATE_BASKET_ITEM,
  DELETE_BASKET_ITEM,
  UPDATE_BASKET_ITEM,
  selectBasketsSelectedBasket,
} from '../../stores/order'
import { useOrderPickerOpener } from './use-order-picker'

export function useSelectedBasketItem(productId: string) {
  const dispatch = useDispatch()
  const selected = useSelector(selectBasketsSelectedBasket)
  const basket = selected && selected.basket
  const item = useMemo(() => {
    if (!basket) {
      return
    }
    const items = basket.items.filter((i) => i.productCode === productId)
    if (!items.length) {
      return
    }
    return items[0]
  }, [basket, productId])

  const { onOpen } = useOrderPickerOpener()

  const onSelectBasket = useCallback(
    (splitQuantity: number, packQuantity: number) => {
      onOpen({
        type: 'ADD_PRODUCT',
        basketItemData: {
          productId,
          splitQuantity,
          packQuantity,
        },
      })
    },
    [onOpen, productId],
  )

  const onUpdate = useCallback(
    (packQuantity: number, splitQuantity: number) => {
      if (!basket) {
        return
      }
      if (item) {
        if (splitQuantity < 1 && packQuantity < 1) {
          dispatch(
            DELETE_BASKET_ITEM.request({
              id: item.id,
              basketId: basket.id,
            }),
          )
        } else {
          dispatch(
            UPDATE_BASKET_ITEM.request({
              id: item.id,
              basketId: basket.id,
              packQuantity,
              splitQuantity,
            }),
          )
        }
      } else if (splitQuantity > 0 || packQuantity > 0) {
        dispatch(
          CREATE_BASKET_ITEM.request({
            basketId: basket.id,
            productId,
            packQuantity,
            splitQuantity,
          }),
        )
      }
    },
    [dispatch, basket, item, productId],
  )

  const onUpdateSplitQuantity = useCallback(
    (quantity: number) => {
      onUpdate(item ? item.packQuantity : 0, quantity)
    },
    [item, onUpdate],
  )

  const onUpdatePackQuantity = useCallback(
    (quantity: number) => {
      onUpdate(quantity, item ? item.splitQuantity : 0)
    },
    [item, onUpdate],
  )

  return {
    basket,
    item,
    onSelectBasket,
    onUpdatePackQuantity,
    onUpdateSplitQuantity,
  }
}
