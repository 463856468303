import { useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import {
  productsListingSelector,
  FETCH_PRODUCTS,
  FETCH_MORE_PRODUCTS,
  getProductSearchQueryString,
  ADD_FACET,
  REMOVE_FACET,
  SET_FACETS,
  REMOVE_ALL_FACETS,
  SORT_PRODUCTS,
} from '../../stores/catalog'

export function useSearchResults(
  filters?: { key: string; value: string }[],
  initialSort?: string[],
) {
  const dispatch = useDispatch()
  const { key, search: qsSearch } = useLocation()
  const { extra, sort, ...listing } = useSelector(productsListingSelector)(key!)

  const { search, query } = useMemo(() => {
    const params = queryString.parse(qsSearch)
    const q = (Array.isArray(params.q) ? params.q[0] : params.q) || ''
    return {
      search: q,
      query: {
        sort: initialSort ?? [],
        ...getProductSearchQueryString(params),
      },
    }
  }, [qsSearch, initialSort])

  const handlers = useMemo(
    () => ({
      onLoadMore: () => dispatch(FETCH_MORE_PRODUCTS.request(key!)),
      onSort: (field?: string) =>
        dispatch(
          SORT_PRODUCTS({
            request: key!,
            field,
          }),
        ),
      onAddFacet: (field: string) =>
        dispatch(
          ADD_FACET({
            request: key!,
            field,
          }),
        ),
      onSetFacets: (fields: string[]) =>
        dispatch(
          SET_FACETS({
            request: key!,
            fields,
          }),
        ),
      onRemoveFacet: (field: string) =>
        dispatch(
          REMOVE_FACET({
            request: key!,
            field,
          }),
        ),
      onRemoveAllFacets: () => dispatch(REMOVE_ALL_FACETS(key!)),
    }),
    [dispatch, key],
  )

  const onLoadInitial = useCallback(() => {
    if (filters) {
      dispatch(
        FETCH_PRODUCTS.request({
          key: key!,
          query: {
            ...query,
            filters: [...(query.filters || []), ...filters],
          },
        }),
      )
    } else {
      dispatch(
        FETCH_PRODUCTS.request({
          key: key!,
          query: {
            ...query,
            search,
          },
        }),
      )
    }
  }, [dispatch, key, query, search, filters])

  // automatically call load initial
  useEffect(() => {
    onLoadInitial()
  }, [onLoadInitial])

  return {
    ...listing,
    totalCount: listing.totalCount ?? 0,
    isLoadingInitial: listing.totalCount === undefined,
    ...handlers,
    sort: sort && sort[0],
    facets: extra || {
      available: [],
      selected: [],
    },
  }
}
