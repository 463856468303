import { all, call, put, takeLeading } from 'redux-saga/effects'

import API, { CallReturnType } from '../../../api'
import { displayErrorToastSaga, ensureError } from '../../../lib'
import * as actions from './actions'

function* fetchRootCategories() {
  try {
    const categories: CallReturnType<typeof API.fetchAllCategories> = yield API.fetchAllCategories({
      pageSize: 500,
      filters: { level: '0' },
    })
    yield put(actions.FETCH_ROOT_CATEGORIES.success(categories))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_ROOT_CATEGORIES.failure(error))
  }
}

function* fetchCategory(action: ReturnType<typeof actions.FETCH_CATEGORY.request>) {
  try {
    const category: CallReturnType<typeof API.fetchCategory> = yield API.fetchCategory(
      action.payload,
    )
    yield put(actions.FETCH_CATEGORY.success(category))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_CATEGORY.failure(error))
  }
}

export function* saga() {
  yield all([
    takeLeading(actions.FETCH_ROOT_CATEGORIES.request, fetchRootCategories),
    takeLeading(actions.FETCH_ROOT_CATEGORIES.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_CATEGORY.request, fetchCategory),
    takeLeading(actions.FETCH_CATEGORY.failure, displayErrorToastSaga),
  ])
}
